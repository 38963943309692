<template lang="pug">
include ../pug/svg
section#messages
	div#ctrl
		div
			Search(v-model:search="search" placeholder="Search messages...")
			button.but.circ.plus.text(type="button" @click="toggleModal(true)")
				span New Message
				+svg(svg-filename="iconPlus" aria-hidden="true" alt="Add icon")
	div.wrapper
		div.c1.listbg
			ul.list
				li.person(v-for="(user, index) in filteredUsers" :key="user.id" :user="user" :index="index" :class="{active:previewIndex===index}" @click="swapIndex(index)")
					picture.user(v-if="user.profileUrl" :class="{online:user.online}")
						img(:src="user.profileUrl" width="40" height="40" :alt="user.fullName")
					span.user(v-else :class="{online:user.online}") {{user.initials}}
					div.hgroup
						h2 {{user.fullName}}
							span.count(:class="{disabled:!unreadUserMessageCount(user.id)}") {{unreadUserMessageCount(user.id)}}
						h3 {{lastUserMessageRecieved(user.id)||lastUserMessageSent(user.id)||'New'}}
							span(:class="{typing:previewUser.id===user.id&&previewUser.typing}") Typing...
					a.arrow View Individual
						+svg(svg-filename='iconArrow')
		div.c2.sticky
			div.preview
				div.recipient(v-if="previewUser")
					picture.user(v-if="previewUser.profileUrl" :class="{online:previewUser.online}")
						img(:src="previewUser.profileUrl" width="40" height="40" :alt="previewUser.fullName")
					span.user(v-else :class="{online:previewUser.online}") {{previewUser.initials}}
					div.hgroup
						h2 {{previewUser.fullName}}
						h5(v-if="previewUser.online") Online
						h5(v-else) Offline
						p(:class="{typing:previewUser.typing}") Typing...
				div.messages
					ul(ref="messages")
						li(v-for="(message, index) in filteredMessages" :class="{own:message.fromId===user.id}")
							span {{message.message}}
							strong(v-if="message.fromId===user.id" :class="{read:message.read}")
								+svg(svg-filename='iconTick')
								em {{formatSent(message.sent)}}
							strong(v-else :class="{read:message.read}")
								em {{formatSent(message.sent)}}
								+svg(svg-filename='iconTick')
				div.message
					input(type="text" placeholder="Type a message here..." v-model="newMessage" @input="typingStart" @keyup.enter="messageUser" @focus="userMessageRead")
					button.but(type="button" :disabled="!newMessage" @click="messageUser")
						span Send
						+svg(svg-filename="iconSend" aria-hidden="true" alt="Add icon")
	div.modal.center(:class="{active:showModal}")
		div.bar
			+svg(svg-filename="iconMore" aria-hidden="true")
			span New Message
			button.but(type="button" @click="toggleModal(false)") Close
				+svg(svg-filename="iconClose")
		div.body
			div#contacts
				Search(v-model:search="contactSearch" placeholder="Search names...")
				div.listbg2
					ul.list
						li.person(v-for="(user, index) in contacts" :key="user.id" :user="user" :index="index" @click="addContact(user)")
							picture.user(v-if="user.profileUrl" :class="{online:user.online}")
								img(:src="user.profileUrl" width="40" height="40" :alt="user.fullName")
							span.user(v-else :class="{online:user.online}") {{user.initials}}
							div.hgroup
								h2 {{user.fullName}}
								h3(v-if="user.online") Online
								h3(v-else) Offline
</template>

<script>
import SocketioService from '../services/SocketioService';
import dayjs from 'dayjs';
import { Form, Field, ErrorMessage } from 'vee-validate';
import * as Yup from 'yup';
import Filter from '../components/Filter';
import Search from '../components/Search';

export default {
	name: 'Messages',
	components: {
		Form,
		Field,
		ErrorMessage,
		Search,
		Filter,
	},
	data() {
		const newMessageSchema = Yup.object().shape({
		});
		return {
			showModal: false,
			search: null,
			contactSearch: null,
			newMessageSchema,
			newMessage: null,
			previewIndex: 0,
			newContacts: [],
			typing: false,
		}
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: 'Messages',
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},
		});

		this.refreshUsers();
		this.refreshMessages();
	},
	computed: {
		user() {
			return this.$store.state.auth.user;
		},
		users() {
			// copy store to local (allows list sorting)
			let users = [...this.$store.state.users.users];

			// remove current user from list
			return users.filter(u => u.id !== this.user.id);
		},
		userMessages() {
			return this.$store.state.users.messages;
		},
		contacts() {
			// get array of ids for users that have sent/received message to current user or are within new contacts array
			const userIds = [...new Set(this.userMessages.flatMap((u) => [u.fromId, u.toId])), ...new Set(this.newContacts.map((u) => u.id))];

			// return users not matching ids or that are within new message users
			let unmessagedUsers = this.users.filter(u => !userIds.includes(u.id));

			// contact modal search term filter
			return (this.contactSearch && this.contactSearch.length > 2) ? unmessagedUsers.filter(u => u.fullName.toLowerCase().includes(this.contactSearch.toLowerCase())) : unmessagedUsers;
		},
		filteredUsers() {
			// only list users that have sent/recieved messages from current user, order by latest message sent
			let userMessages = [];

			for (let message of this.userMessages) {
				const userId = (message.toId !== this.user.id) ? message.toId : message.fromId;
				// serch term filter
				if ((! this.search || this.search.length <= 2) || message.message.toLowerCase().includes(this.search.toLowerCase())) {
					const i = userMessages.findIndex(u => u.userId === userId);

					if (i > -1) {
						// update user with later message time
						userMessages[i].sent = message.sent;

					} else {
						// add user
						userMessages.push({
							userId: userId,
							sent: message.sent,
							user: this.users.find(u => u.id === userId),
						});
					}
				}
			}

			// list new contacts in reverse order added
			const newContacts = [...this.newContacts].reverse();

			// order users by latest message sent
			const filteredUsers = [...newContacts, ...userMessages.sort((a, b) => (a.sent > b.sent ? -1 : 1)).map(m => m.user)];

			return filteredUsers;
		},
		filteredMessages() {
			const user = this.previewUser;
			const messages = (user) ? this.userMessages.filter(m => (m.toId === user.id && m.fromId === this.user.id) || (m.fromId === user.id && m.toId === this.user.id)) : [];

			// scroll message window (after dom updated)
			this.$nextTick(() => {
				if (this.$refs.messages) {
					this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;
				}
			});

			return messages;
		},
		previewUser() {
			return this.filteredUsers[this.previewIndex];
		},
	},
	methods: {
		addContact(user) {
			this.newContacts.push(user);
			this.toggleModal(false);
		},
		toggleTint(bool) {
			this.$store.dispatch('gui/setTintState', bool);
		},
		toggleModal(bool) {
			this.showModal = bool;

			if (this.showModal) {
				this.toggleTint(true);
			} else {
				this.toggleTint(false);
			}
		},
		async refreshUsers() {
			// load users from db
			await this.$store.dispatch('users/dbUsers');
		},
		async refreshMessages() {
			// load messages from db
			await this.$store.dispatch('users/dbMessages');
		},
		messageUser() {
			// build message data
			const data = {
				userId: this.previewUser.id,
				message: this.newMessage,
			};

			// message selected user
			SocketioService.messageToUser(data, callback => {
				// update chat messages
				this.$store.dispatch('users/setMessage', callback);

				// remove messaged user if within new contacts array
				this.newContacts = this.newContacts.filter(c => c.id !== this.previewUser.id);

				// scroll message window (after dom updated)
				this.$nextTick(() => {
					this.$refs.messages.scrollTop = this.$refs.messages.scrollHeight;

					// reset index as messaged user is not most recent
					this.previewIndex = 0;

					// clear message field
					this.newMessage = null;
				});
			});
		},
		messageHubRoom() { // phase two
//			SocketioService.messageHubRoom({...});
		},
		formatSent(date) {
			const now = new Date();
			const sent = new Date(date);
			let formatted;

			if (now.getFullYear() !== sent.getFullYear()) {
				// different year
				formatted = dayjs(sent).format('MMM D, YYYY / h:mma');

			} else if (now.toLocaleDateString() !== sent.toLocaleDateString()) {
				// different date
				now.setDate(now.getDate() - 1);
				const yesterday = now;

				if (yesterday.toLocaleDateString() === sent.toLocaleDateString()) {
					// yesterday's date
					formatted = 'Yesterday ' + dayjs(sent).format('h:mma');

				} else {
					formatted = dayjs(sent).format('MMM D / h:mma');
				}

			} else {
				// same date
				formatted = 'Today ' + dayjs(sent).format('h:mma');
			}

			return formatted;
		},
		swapIndex(index) {
			this.previewIndex = index;

			this.userMessageRead();
		},
		userMessageRead() {
			const data = { userId: this.previewUser.id };
			// notify sender that the recipient has read their messages
			SocketioService.userMessageRead(data, callback => {
				// on confirmation of sender store update, update recipient store
				this.$store.dispatch('users/setMessageRead', callback);
			});
		},
		unreadUserMessageCount(userId) {
			const unreadMessages = this.userMessages.filter(m => m.fromId === userId && !m.read);
			return unreadMessages.length;
		},
		lastUserMessageSent(userId) {
			// sort sent messages from specific user newest to oldest
			const recievedMessages = this.userMessages.filter(m => m.toId === userId);
			recievedMessages.sort((a, b) => (a.sent > b.sent) ? -1 : 1);

			// return latest message
			return (recievedMessages.length) ? this.formatSent(recievedMessages[0].sent) : null;
		},
		lastUserMessageRecieved(userId) {
			// sort recieved messages from specific user newest to oldest
			const recievedMessages = this.userMessages.filter(m => m.fromId === userId);
			recievedMessages.sort((a, b) => (a.sent > b.sent) ? -1 : 1);

			// return latest message
			return (recievedMessages.length) ? this.formatSent(recievedMessages[0].sent) : null;
		},
		typingStart() {
			const delay = 2000;
			let data = {
				typing: true,
				toId: this.previewUser.id,
//				fromId: this.user.id,
			};

			if (this.typing === false) {
				this.typing = data.typing;

				this.timeout = setTimeout(() => {
					this.typingStop(data);
				}, delay);

				SocketioService.typingToUser(data);

			} else {
				clearTimeout(this.timeout);

				this.timeout = setTimeout(() => {
					this.typingStop(data);
				}, delay);
			}
		},
		typingStop(data) {
			data.typing = false;
			this.typing = data.typing;

			SocketioService.typingToUser(data);
		},
	},
}
</script>

<style lang="scss">
#messages {
	.wrapper {
		display: flex;
		width: 100%;
		flex-grow: 1; // vertically
		.c1 {
			flex-grow: 1;
			margin-right: 20px;
		}
		.c2 {}
		.list {
			li {
				cursor: pointer;
				h2 {
					.count {
						position: absolute;
						right: 20px;
						top: 50%;
						transform: translateY(-50%);
					}
				}
				h3 {
					span {
						margin-left: 5px;
						opacity: 0;
						color: var(--pri-bg);
						text-transform: capitalize;
						transition: .3s ease-in-out;
						&.typing {
							opacity: 1;
						}
					}
				}
			}
		}
		.preview {
			position: relative;
			display: flex;
			justify-content: space-between;
			min-height: 350px;
			max-height: calc(100vh - 220px);
			padding-bottom: 90px; // space for .message
			// overrides
			box-shadow: none !important;
			background: none;
			overflow: visible;
			>* {
				border-radius: 8px;
				background: var(--pan-bg);
			}
			div.messages {
				overflow: hidden;
				position: relative;
				flex-grow: 1;
				width: 340px;
				margin-bottom: 0;
			}
			ul {
				height: 100%;
				padding: 20px 20px 6px 20px;
				overflow-x: hidden;
				overflow-y: scroll;
				&:before,
				&:after { // top/bottom fades
					pointer-events: none;
					z-index: 1;
					content: '';
					position: absolute;
					left: 0;
					width: calc(100% - 20px); // clear scrollbar
					height: 20px;
					display: block;
				}
				&:before {
					top: 0;
					background-image: linear-gradient(var(--pan-bg), transparent);
				}
				&:after {
					bottom: 0;
					background-image: linear-gradient(transparent, var(--pan-bg));
				}
				li {
					order: 1;
					list-style-type: none;
					span {
						position: relative;
						display: inline-flex;
						align-items: center;
						min-height: 40px;
						padding: 10px 20px;
						border-radius: 20px;
						font-size: 1.4rem;
						&:after {
							content: '';
							position: absolute;
							bottom: 0;
							width: 0;
							height: 0;
							border-left: 8px solid transparent;
							border-right: 8px solid transparent;
							border-bottom: 8px solid transparent;
						}
					}
					strong {
						display: flex;
						padding: 6px 5px 14px 5px;
						font-size: 1rem;
						font-weight: 500;
						text-transform: uppercase;
						line-height: 1;
						color: var(--pan-sub-col);
						em {
							font-style: normal;
						}
						svg {
							opacity: 0;
							transition-duration: 0s;
							.fill {
								fill: var(--pan-sub-col);
							}
						}
						&.read {
							svg {
								opacity: 1;
								transition-duration: .3s;
							}
						}
					}
					&.own {
						text-align: right;
						strong {
							justify-content: flex-end;
							svg {
								margin-right: 5px;
							}
						}
						span {
							color: var(--pri-bg);
							background: var(--bod-bg);
							&:after {
								right: 0;
								border-bottom-color: var(--bod-bg);
							}
						}
					}
					&:not(.own) {
						text-align: left;
						strong {
							justify-content: flex-start;
							svg {
								margin-left: 5px;
							}
						}
						span {
							color: var(--pri-col);
							background: var(--pri-bg);
							&:after {
								left: 0;
								border-bottom-color: var(--pri-bg);
							}
						}
					}
				}
			}
			>.recipient {
				overflow: hidden;
				width: 250px;
				padding: 20px;
				margin-right: 10px;
				.user {
					position: relative;
					top: auto;
					right: auto;
					width: 120px;
					height: 120px;
					margin-left: auto;
					margin-right: auto;
					&:after {
						transform: translate(25%, 25%);
					}
				}
				span.user {
					font-size: 2rem;
					font-weight: normal;
				}
				.hgroup {
					margin-top: 20px;
					text-align: center;
					h2 {
						margin-bottom: 5px;
						font-size: 2rem;
						font-weight: 400;
						color: var(--pan-hdg-col);
					}
					p {
						font-size: 1.2rem;
						margin: 0;
						color: var(--pri-bg);
						opacity: 0;
						transition: .3s ease-in-out;
						&.typing {
							opacity: 1;
						}
					}
				}
			}
			.message {
				position: absolute;
				bottom: 0;
				display: flex;
				height: 80px;
				width: 100%;
				padding: 20px;
				input {
					width: 100%;
					height: 40px;
					padding: 0 60px 0 20px;
					border-radius: 20px;
					background: var(--bod-bg);
				}
				button {
					display: flex;
					align-items: center;
					justify-content: center;
					width: 30px;
					height: 30px;
					margin: 5px 0 0 -35px;
					text-indent: 40px;
					border-radius: 50%;
					background: var(--pri-bg);
					.fill {
						fill: var(--pri-col);
					}
					&:hover {
						background: var(--pri-bg-hvr);
						.fill {
							fill: var(--pri-col-hvr);
						}						
					}
					&:disabled {
						opacity: 0;
					}
					svg {
						height: 18px;
						width: 18px;
						margin-left: -2px;
					}
				}
			}
		}
	}
	#contacts {
		.search {
			width: calc(50% - 10px);
			input {
				width: 100%;
			}
		}
	}
	.listbg2 {
		.list {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin-top: 20px;
			min-width: 300px;
			li {
				cursor: pointer;
				width: calc(50% - 10px);
				transition: border .3s ease-in-out;
				border: 1px solid transparent;
				&:hover {
					border-color: var(--pri-bg);
					/*background: $lightgrey;
					picture.user:after,
					span.user:after {
						border-color: $lightgrey;
					}*/
				}
			}
		}
	}
	@media only screen and (max-width:659px) {
		.listbg2 {
			&:before,
			&:after {
				width: 100%;
			}
			.list {
				li {
					width: 100%;
				}
			}
		}
	}
	@media screen and (max-width: 1239px) {
		.preview {
			>.recipient {
				display: none;
			}
		}
	}
}
</style>
